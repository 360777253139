import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Package from "@/views/Package/Package.vue";
import Gallery from "@/views/Gallery.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/package",
    name: "Package",
    component: Package,
  },
  {
    path: "/package/:slug",
    name: "Package",
    component: Package,
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;