<template>
  <div class="festivals" >
     <div class="container">
       <div class="breadcrumbs">
         <button class="breadcrumbs__btn" @click="$router.push('/')">
           <svg-icon name="arrow" class="button__arrow"/>
           <p>на главную</p>
         </button>
       </div>

        <div class="title festivals__title">Альманах Панчлайна</div>

        <Fancybox
            :options="{
            Carousel: {
              infinite: false,
            },
          }"
        >
        <swiper-container
            slides-per-view="5"
            space-between="50"
            navigation="true"
            :breakpoints="swiperBreakpoints"

        >

          <swiper-slide
              v-for="index in 121"
              :key="index"
              lazy="true"
          >
            <a v-if="index < 10" :href="'/uploads/big/00' + index +'.jpg'" data-fancybox="gallery" class="link-gallery">
              <img loading="lazy" :src="'/uploads/small/00' + index +'.jpg'" />
            </a>
            <a v-if="index >= 10 && index < 100" :href="'/uploads/big/0' + index +'.jpg'" data-fancybox="gallery" class="link-gallery">
              <img loading="lazy" :src="'/uploads/small/0' + index +'.jpg'" />
            </a>
            <a v-if="index >= 100" :href="'/uploads/big/' + index +'.jpg'" data-fancybox="gallery" class="link-gallery">
              <img loading="lazy" :src="'/uploads/small/' + index +'.jpg'" />
            </a>
          </swiper-slide>

        </swiper-container>
        </Fancybox>


       <div class="festivals-list">
         <div class="festivals-list__wrapper-btn">
           <button class="button button--blue festivals-list__btn" style="margin: 30px auto 0 auto;"
                   @click="showGallery"
           >
             Посмотреть
             <svg-icon name="playslide" class="button__pdf button__arrow"></svg-icon>
           </button>
         </div>

         <div class="festivals-list__wrapper-btn">
           <a class="button button--pink festivals-list__btn" style="margin: 30px auto 0 auto;"
               href="/uploads/journal.pdf" target="_blank"
           >
             Скачать PDF

             <svg-icon name="pdf" class="button__pdf button__arrow"></svg-icon>

           </a>
         </div>
       </div>
      </div>



  </div>
</template>

<script>
import Fancybox from '../Fancybox.vue';
import SvgIcon from "@/components/SvgIcon";


export default {
  name: "Gallery",
  components: {
    Fancybox, SvgIcon,

  },
  data() {
    return {
      swiperBreakpoints: {
        "0": {
          slidesPerView: 3,
          "allowTouchMove": true,
          spaceBetween: 20
        },
        "1200": {
          "slidesPerView": 5,
          "allowTouchMove": true,
        }
      }
    }
  },
  methods:{
      showGallery: function () {
        //console.log();
        document.getElementsByClassName('link-gallery')[0].click();
      }
  },
}
</script>

<style lang="scss" scoped>
swiper-slide img {
  width: 100%;
}

.festivals-list {
  position: relative;
  margin-top: 50px;
  @media (max-width: 1200px) {
    margin-top: 40px;
  }
  @media (max-width: 768px) {
    margin-top: 50px;
  }
  @media (max-width: 576px) {
    margin-top: 0;
    z-index: 26;
  }

  &__box {
    display: flex;
    align-items: center;
  }

  &__bell {
    width: 35px;
    height: auto;
    object-fit: contain;
    margin-right: 21px;
    @media (max-width: 992px) {
      display: none;
    }
  }

  &__icon {
    width: 19px;
    height: 23px;
    margin-right: 13px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 32px;
    position: relative;
    z-index: 1;
    @media (max-width: 576px) {
      margin-bottom: 15px;
    }
  }

  &__hint {
    display: flex;
    align-items: center;
  }

  &__hint-text {
    font-size: 12px;
    line-height: 16px;
    color: #000;
    border-bottom: 1px dotted #000;
  }

  &__wrapper-btn {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }

  &__btn {
    display: inline-flex;
    width: auto;
    min-width: 240px;
    margin: 107px auto 0 auto;
    @media (max-width: 768px) {
      margin-top: 80px;
    }
    @media (max-width: 576px) {
      margin-top: 60px;
    }
  }

  &__item {
    margin-bottom: 61px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      margin-bottom: 50px;
    }
    @media (max-width: 576px) {
      margin-bottom: 30px;
    }
  }
}

.festivals {
  padding: 87px 0 179px 0;
  position: relative;
  @media (max-width: 1450px) {
    padding: 80px 0 170px 0;
  }
  @media (max-width: 992px) {
    padding-bottom: 150px;
  }
  @media (max-width: 768px) {
    padding-bottom: 110px;
    padding-top: 60px;
  }
  @media (max-width: 576px) {
    padding: 95px 0 80px 0;
    //padding-top: 40px;
  }
  &__title {
    margin-bottom: 10px;
    padding-right: 40px;
    position: relative;
    display: inline-block;
    @media (max-width: 992px) {
      margin-bottom: 26px;
    }
    @media (max-width: 576px) {
      margin-bottom: 15px;
    }
  }
  &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
}

.error {
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  margin-top: 50px;
  @media (max-width: 576px) {
    font-size: 18px;
  }
}

.breadcrumbs {
  margin-bottom: 40px;
  &__btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    @media (max-width: 576px) {
      gap: 6px;
    }
    .button__arrow {
      transform: rotate(180deg);
      margin-left: 0;
      @media (max-width: 576px) {
        width: 10px;
      }
    }
    & p {
      font-weight: 600;
      color: $purple;
      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
  }
  @media (max-width: 576px) {
    margin-bottom: 15px;
  }
}
</style>